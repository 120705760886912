import React, {useEffect, useState} from "react";
import moment from 'moment';
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import {useAuth} from "../../services/authService.js";
import {useLocation, useParams} from "react-router-dom/cjs/react-router-dom.js";
import PageWrapper from "../components/PageWrapper.js";
import {landlordService} from "../../services/landlordService.js";
import {ReactGrid} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

const useQuery = () => {
    let searchParams = {};
    new URLSearchParams(useLocation().search).forEach((value, key) => {
        searchParams[key] = value;
    });
    return searchParams;
};

const LandlordEditListings = () => {
    const {setModal} = useDialogContext();
    let {user} = useAuth();
    let {landlord_id} = useParams();
    let {page: _defaultPage} = useQuery();

    let [listings, setListings] = useState(null);
    let [total, setTotal] = useState(0);
    let [page, setPage] = useState(parseInt(_defaultPage) || 1);
    let [landlord, setLandlord] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                let _landlord = await landlordService.getLandlordById(landlord_id);
                setLandlord(_landlord);
            } catch (e) {
                cogoToast.error("Error getting landlord");
            }
        })();
    }, [landlord_id])

    useEffect(() => {
        (async () => {
            try {
                let _listings = await landlordService.getElasticListings(landlord_id, page - 1, {}, 100);
                let overrideChanges = JSON.parse(localStorage.getItem("landlord_edit_listings_override_changes")||"{}");
                setListings(_listings.listings.map(listing => {
                    let listing_id = listing.id + "_" + listing.room_id;
                    if (overrideChanges[listing_id]) {
                        if (overrideChanges[listing_id].lastElasticUpdate > listing.lastElasticUpdate) {
                            listing = {...listing, ...overrideChanges[listing_id]};
                        } else {
                            delete overrideChanges[listing_id];
                        }
                    }
                    return listing;
                }));
                localStorage.setItem("landlord_edit_listings_override_changes", JSON.stringify(overrideChanges));
                setTotal(_listings.total);
            } catch (e) {
                cogoToast.error("Error getting listings");
            }
        })();
    }, [page]);

    let defaultColumns = {
        "property_id": 76,
        "room_id": 76,
        "address": 300,
        "internal_name": 110,
        "fixed_rent": 80,
        "rent": 92,
        "rent_jan": 92,
        "rent_feb": 92,
        "rent_mar": 92,
        "rent_apr": 92,
        "rent_may": 92,
        "rent_jun": 92,
        "rent_jul": 92,
        "rent_aug": 92,
        "rent_sep": 92,
        "rent_oct": 92,
        "rent_nov": 92,
        "rent_dec": 92,
        "preview": 70
    }

    let _columns = JSON.parse(localStorage.getItem("landlord_edit_listings_columns") || "null");
    if(!_columns) {
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(defaultColumns));
        _columns = defaultColumns;
    } else if (Object.keys(_columns).length !== Object.keys(defaultColumns).length) {
        _columns = {...defaultColumns, ..._columns};
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(_columns));
    }

    const [columns, setColumns] = useState(Object.keys(_columns).map(columnId => {
        return { columnId, width: _columns[columnId], resizable: true };
    }));

    const handleResizeColumn = (columnId, width) => {
        setColumns(prev => {
            const columnIndex = prev.findIndex(el => el.columnId === columnId);
            const resizedColumn = prev[columnIndex];
            prev[columnIndex] = {...resizedColumn, width};
            return [...prev];
        });
        localStorage.setItem("landlord_edit_listings_columns", JSON.stringify(columns.reduce((acc, column) => {
            if(column.columnId === columnId) {
                acc[columnId] = Math.round(width);
            } else {
                acc[column.columnId] = column.width;
            }
            return acc;
        }, {})));
    }

    const handleCellsChanged = async (changes) => {
        console.log("changes", changes);
        let overrideChanges = JSON.parse(localStorage.getItem("landlord_edit_listings_override_changes")||"{}");
        let new_listings = [...listings];
        let listing_changes = {};
        let has_changes = false;
        changes.forEach((change) => {
            let listing = new_listings[change.rowId];
            let listing_id = listing.id + "_" + listing.room_id;
            if(!listing_changes[listing_id]) {
                listing_changes[listing_id] = {
                    room_changes: {},
                    //TODO: property_changes: {}
                };
            }
            if(!overrideChanges[listing_id]) {
                overrideChanges[listing_id] = {};
            }

            if (change.columnId === "rent" && listing.fixedRent) {
                listing.rent = change.newCell.value;
                overrideChanges[listing_id].rent = change.newCell.value;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                listing_changes[listing_id].room_changes.rent = change.newCell.value;
                has_changes = true;
            }
            if (change.columnId.startsWith("rent_") && !listing.fixedRent) {
                let month = moment().locale("en").startOf("month").month(change.columnId.split("_")[1]).month();
                listing.rentMonths[month] = change.newCell.value;
                overrideChanges[listing_id].rentMonths = listing.rentMonths;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                listing_changes[listing_id].room_changes.rentMonths = listing.rentMonths.reduce((acc, rent, i) => {
                    let month_full = moment().locale("en").startOf("month").month(i).format("MMMM").toLowerCase();
                    acc[month_full] = rent;
                    return acc;
                }, {});
                has_changes = true;
            }
            if (change.columnId === "fixed_rent") {
                listing.fixedRent = !!change.newCell.checked;
                listing_changes[listing_id].room_changes.fixedRent = listing.fixedRent;
                overrideChanges[listing_id].fixedRent = listing.fixedRent;
                overrideChanges[listing_id].lastElasticUpdate = Date.now();
                has_changes = true;
            }
        });
        localStorage.setItem("landlord_edit_listings_override_changes", JSON.stringify(overrideChanges));
        setListings(new_listings);
        if(has_changes) {
            try {
                await landlordService.advancedUpdateListings(landlord_id, listing_changes);
                cogoToast.success("Listings updated");
            } catch (e) {
                cogoToast.error("Error updating listings");
            }
        }
    }

    const handleFocusLocationChanging = (location) => {
        if(location.columnId === "preview") {
            let listing = listings[location.rowId];
            let house_path = listing.url_slugs ? listing.url_slugs["en"] : (listing.id + (listing.accommodation === "apartment" ? '' : ("/" + listing.room_id)));
            window.open(process.env.REACT_APP_STUDENT_URL + "/house/" + house_path, '_blank');
            return false;
        }
        return true;
    }

    const handlePrevPage = () => {
        if(page <= 1) return;
        setPage(page - 1);
    }
    const handleNextPage = () => {
        if(page === Math.floor(total / 100)) return;
        setPage(page + 1);
    }

    useEffect(() => {
        window.history.pushState({}, "", `?page=${page}`);
    }, [page]);

    return (
        <PageWrapper title={"Edit Listings"}
                     breadcrumbs={[
                         {url: "landlords", title: "Landlords"},
                         {url: "landlords/" + landlord_id, title: landlord?.name||"Landlord"},
                         {title: "Edit Listings"}
                     ]}
                     noCard>
            {!listings ? <div>Loading...</div> : <div className={"ReactGrid-Wrapper"}>
                <ReactGrid
                    onColumnResized={handleResizeColumn}
                    onCellsChanged={handleCellsChanged}
                    onFocusLocationChanging={handleFocusLocationChanging}
                    enableRangeSelection
                    columns={columns}
                    rows={[
                        {
                            rowId: "header",
                            cells: [
                                { type: "header", text: "Property ID" },
                                { type: "header", text: "Room ID" },
                                { type: "header", text: "Address" },
                                { type: "header", text: "Internal Name" },
                                { type: "header", text: "Fixed Rent" },
                                { type: "header", text: "Rent" },
                                ...(new Array(12).fill(0).map((_, i) => {
                                    return { type: "header", text: moment().locale("en").month(i).format("MMM") }
                                })),
                                { type: "header", text: "Preview" }
                            ]
                        },
                        ...listings.map((listing, i) => {
                            return {
                                rowId: i,
                                cells: [
                                    { type: "text", text: listing.id, className: "fixed-width" },
                                    { type: "text", text: listing.room_id, className: "fixed-width" },
                                    { type: "text", text: listing.address },
                                    { type: "text", text: listing.internalName },
                                    { type: "checkbox", checked: !!listing.fixedRent },
                                    listing.fixedRent ?
                                        { type: "number", value: listing.rent, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) } :
                                        { type: "text", text: "-", className: "disabled-cell" },
                                    ...(new Array(12).fill(0).map((_, j) => {
                                        if(listing.fixedRent) return { type: "text", text: "-", className: "disabled-cell" };
                                        return { type: "number", value: (listing.rentMonths?.[j])||0, format: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }) }
                                    })),
                                    { type: "text", text: "↗", className: "clickable-cell" }
                                ]
                            };
                        })
                    ]}
                />
                <button className="btn btn-primary" onClick={handlePrevPage} disabled={page <= 1}>Previous</button>
                <button className="btn btn-primary" onClick={handleNextPage} disabled={page === Math.floor(total / 100)}>Next</button>
            </div>}
        </PageWrapper>
    );
}

export default LandlordEditListings;
